.amount_style[data-v-34420532] {
  width: 100%;
  cursor: pointer;
}
.amount_style > div[data-v-34420532] {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.content_data .content_data_inner_div[data-v-34420532] {
  display: flex;
  align-items: center;
  width: 98%;
  margin: 0 auto;
  height: 70px;
}
.collect-invoice-container[data-v-34420532] {
  position: relative;
}
.qzf-video-box[data-v-34420532] {
  position: absolute;
  top: 10px;
  right: 13px;
  z-index: 999;
}
.styleForm[data-v-34420532] {
  margin: 0px 0px 0px 6px;
  width: 615px;
}