.dialog-footer[data-v-21aa15db] {
  text-align: right;
  margin-top: 10px;
}
.always[data-v-21aa15db] {
  background-color: rgba(231, 231, 231, 0.3490196078);
  padding: 10px;
  margin-bottom: 20px;
}
.always .top[data-v-21aa15db] {
  padding: 10px;
}
.always .top .zzs[data-v-21aa15db] {
  font-size: 15px;
  font-weight: 500;
  color: #000;
}
.always .center[data-v-21aa15db] {
  padding: 10px;
}
.always .footer[data-v-21aa15db] {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}
.recommend[data-v-21aa15db] {
  color: white;
  background-color: #cd1a1a;
  border-radius: 6px;
  padding: 1px 2px;
  margin-left: 20px;
}
.always1[data-v-21aa15db],
.alwaysT[data-v-21aa15db] {
  background-color: rgba(231, 231, 231, 0.3490196078);
  padding: 10px;
  position: relative;
  margin-bottom: 20px;
}
.always1 .hint[data-v-21aa15db],
.alwaysT .hint[data-v-21aa15db] {
  position: absolute;
  right: 1px;
  top: 0;
  background-color: #b5b0b0;
  color: white;
  padding: 0 8px;
}
.always1 .top[data-v-21aa15db],
.alwaysT .top[data-v-21aa15db] {
  padding: 10px;
}
.always1 .top .zzs[data-v-21aa15db],
.alwaysT .top .zzs[data-v-21aa15db] {
  font-size: 15px;
  font-weight: 500;
  color: #000;
}
.always1 .footer[data-v-21aa15db],
.alwaysT .footer[data-v-21aa15db] {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}
.logo[data-v-21aa15db] {
  width: 20px;
  vertical-align: bottom;
  padding-right: 5px;
}