[data-v-34420532] .el-tabs--border-card>.el-tabs__content {
  padding: 0px 15px 5px 15px;
}
[data-v-34420532] .el-range-input {
  font-size: 13px !important;
}
[data-v-34420532] .el-range-separator {
  font-size: 13px !important;
}
.right_box[data-v-34420532] {
  float: right;
  margin-bottom: 10px;
}
.content_data[data-v-34420532] {
  margin: 5px;
  background-color: #d8ecff;
}
.content_data .each[data-v-34420532] {
  width: 200px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333;
}
.content_data .each span[data-v-34420532] {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}
.content_data .each a[data-v-34420532] {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  -webkit-text-decoration-color: #141720;
          text-decoration-color: #141720;
}
.content_data .icon_bg[data-v-34420532] {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #aedcfb;
  text-align: center;
  line-height: 35px;
}
.content_data .iconfont[data-v-34420532] {
  font-size: 18px;
  color: #17a2b8;
}
.w140[data-v-34420532] {
  width: 140px !important;
}
.margin0[data-v-34420532] {
  margin: 0;
}