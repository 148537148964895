.top_btns[data-v-34420532] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 5px 0;
}
.el-table__column-filter-trigger .el-icon-arrow-down[data-v-34420532] {
  color: white !important;
  width: 10px;
}
.none-borders .div_p[data-v-34420532] {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.none-borders .div_p[data-v-34420532]:last-child {
  border-bottom: none;
}
.item_icon[data-v-34420532] {
  cursor: pointer;
}
.item_icon i[data-v-34420532] {
  display: inline-block;
  font-size: 13px;
  margin-right: 4px;
}
.item_icon p[data-v-34420532] {
  font-size: 13px;
  color: #333;
  text-align: left;
}